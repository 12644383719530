<template>
  <div class="documents-page-container">
    <h1 v-if="src != 'admin'">Documents</h1>
    <alert v-if="folders.isLoading || loading" class="light-shadow" />
    <div
      v-if="!folders.isLoading && !loading && folders.data.length > 0"
      class="admin-documents-container card theme-card px-4 py-3"
    >
      <div class="mb-2 font-weight-bold">
        Please select a folder to share or expand
      </div>
      <div class="row m-0 p-3 d-flex flex-row folders-container">
        <div
          class="
            custom-control custom-checkbox
            col-12 col-md-6 col-lg-4
            pl-0 pl-lg-3
          "
          v-for="f in folders.data"
          :key="f.id"
        >
          <span
            class="mr-5 text-danger pointer-cursor"
            :class="
              !f.id || !user || !(user.isAdmin || user.isManagingAdmin)
                ? 'transparent'
                : ''
            "
            ><i
              :class="`${
                f.deleting ? 'fa fa-spin fa-circle-notch' : 'fas fa-trash-alt'
              }`"
              @click="
                f.id &&
                  user &&
                  !f.deleting &&
                  (user.isAdmin || user.isManagingAdmin) &&
                  deleteFolderModal(f)
              "
            ></i
          ></span>
          <input
            type="radio"
            name="customRadio"
            v-model="selectedFolderId"
            v-bind:value="f.id"
            @change="onSelectedFolderChanged"
            class="custom-control-input"
            :id="`folder-${f.id}`"
          />
          <label class="custom-control-label" :for="`folder-${f.id}`"
            >{{ f.name }} ({{ f.count }})
            <i
              v-if="f.action_type"
              class="fa fa-info-circle ml-2 pointer-cursor"
              v-tooltip.top="
                `${
                  f.action_type === 'shared' ? 'Shared' : 'Created'
                } on: ${prettyDate(f.action_date)}`
              "
            ></i
          ></label>
        </div>
      </div>
    </div>
    <div
      class="my-3 m-0 row align-items-center"
      v-if="user && (user.isAdmin || user.isManagingAdmin)"
    >
      <label class="control-label col-12 col-md-auto pl-0 mb-0 font-weight-bold"
        >Create New Folder</label
      >
      <div class="input-group col-12 col-md-4 pl-0 pl-md-3 pr-0 pr-md-3">
        <input
          type="text"
          v-model="folder"
          class="form-control"
          :disabled="folders.isSaving"
        />
      </div>
      <div class="col-auto mt-3 mt-md-0 pl-0 pl-md-3">
        <save
          @click="createFolder"
          :saving="folders.isSaving"
          classes="btn btn-outline-blue"
        >
          Create
        </save>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Save from "../../../../components/Save";
import Swal from "sweetalert2";
import helpers from "@/utils/helpers";

export default {
  name: "Folders",
  components: { Save },
  data() {
    return {
      loading: true,
      folder: "",
      selectedFolderId: undefined,
    };
  },
  computed: {
    ...mapState({
      folders: (state) => state.folders,
      userInfo: (state) => state.auth.userInfo,
      user: (state) => state.auth.user,
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  mounted() {
    this.loading = true;
    this.getUserInfo().then(() => {
      this.loading = false;
      this.get(this.userInfo.id);
    });
  },
  methods: {
    ...mapActions({
      save: "folders/save",
      get: "folders/get",
      deleteFolder: "folders/deleteFolder",
      getUserInfo: "auth/getUserInfo",
    }),
    createFolder() {
      const data = {
        name: this.folder,
        user_id: this.userInfo.id,
      };
      this.save(data)
        .then((val) => {
          if (val && val.data) {
            this.get(this.userInfo.id);
          } else {
            Swal.fire("Error", val.error.message, "error");
          }
          this.folder = null;
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire("Error", err.data.error.message, "error");
          }
        });
    },
    deleteFolderModal(folder) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            folder.deleting = true;
            vm.$forceUpdate();
            var payload = { folderId: folder.id, userId: this.userInfo.id };
            vm.deleteFolder(payload).then((deleted) => {
              folder.deleting = false;
              vm.$forceUpdate();
              vm.get(this.userInfo.id);
              if (deleted) {
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your folder has been deleted.",
                  "success"
                );
                vm.onSelectedFolderDeleted();
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
    onSelectedFolderChanged() {
      this.$emit("selectedFolderChanged", this.selectedFolderId);
    },
    onSelectedFolderDeleted() {
      this.$emit("selectedFolderDeleted");
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
  },
};
</script>
<style scoped lang="scss">
.folders-container {
  box-shadow: none;

  @media (max-width: 1024px) {
    padding: 0 !important;
    align-items: center;
  }
}
</style>

<style lang="scss">
.admin-documents-container {
  margin-bottom: 30px;
}
.custom-control-input {
  z-index: -1 !important;
}
</style>
