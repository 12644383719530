var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documents-page-container"},[(_vm.src != 'admin')?_c('h1',[_vm._v("Documents")]):_vm._e(),(_vm.folders.isLoading || _vm.loading)?_c('alert',{staticClass:"light-shadow"}):_vm._e(),(!_vm.folders.isLoading && !_vm.loading && _vm.folders.data.length > 0)?_c('div',{staticClass:"admin-documents-container card theme-card px-4 py-3"},[_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" Please select a folder to share or expand ")]),_c('div',{staticClass:"row m-0 p-3 d-flex flex-row folders-container"},_vm._l((_vm.folders.data),function(f){return _c('div',{key:f.id,staticClass:"custom-control custom-checkbox col-12 col-md-6 col-lg-4 pl-0 pl-lg-3"},[_c('span',{staticClass:"mr-5 text-danger pointer-cursor",class:!f.id || !_vm.user || !(_vm.user.isAdmin || _vm.user.isManagingAdmin)
              ? 'transparent'
              : ''},[_c('i',{class:`${
              f.deleting ? 'fa fa-spin fa-circle-notch' : 'fas fa-trash-alt'
            }`,on:{"click":function($event){f.id &&
                _vm.user &&
                !f.deleting &&
                (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
                _vm.deleteFolderModal(f)}}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFolderId),expression:"selectedFolderId"}],staticClass:"custom-control-input",attrs:{"type":"radio","name":"customRadio","id":`folder-${f.id}`},domProps:{"value":f.id,"checked":_vm._q(_vm.selectedFolderId,f.id)},on:{"change":[function($event){_vm.selectedFolderId=f.id},_vm.onSelectedFolderChanged]}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":`folder-${f.id}`}},[_vm._v(_vm._s(f.name)+" ("+_vm._s(f.count)+") "),(f.action_type)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
              `${
                f.action_type === 'shared' ? 'Shared' : 'Created'
              } on: ${_vm.prettyDate(f.action_date)}`
            ),expression:"\n              `${\n                f.action_type === 'shared' ? 'Shared' : 'Created'\n              } on: ${prettyDate(f.action_date)}`\n            ",modifiers:{"top":true}}],staticClass:"fa fa-info-circle ml-2 pointer-cursor"}):_vm._e()])])}),0)]):_vm._e(),(_vm.user && (_vm.user.isAdmin || _vm.user.isManagingAdmin))?_c('div',{staticClass:"my-3 m-0 row align-items-center"},[_c('label',{staticClass:"control-label col-12 col-md-auto pl-0 mb-0 font-weight-bold"},[_vm._v("Create New Folder")]),_c('div',{staticClass:"input-group col-12 col-md-4 pl-0 pl-md-3 pr-0 pr-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.folder),expression:"folder"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.folders.isSaving},domProps:{"value":(_vm.folder)},on:{"input":function($event){if($event.target.composing)return;_vm.folder=$event.target.value}}})]),_c('div',{staticClass:"col-auto mt-3 mt-md-0 pl-0 pl-md-3"},[_c('save',{attrs:{"saving":_vm.folders.isSaving,"classes":"btn btn-outline-blue"},on:{"click":_vm.createFolder}},[_vm._v(" Create ")])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }